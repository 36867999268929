import React from "react";
import "./ModalComponent.css";
import { IoCloseSharp } from "react-icons/io5";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="closeIcon" onClick={onClose}>
          <IoCloseSharp size={24} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
