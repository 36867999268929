import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Main = styled.div`
  position: absolute;
  top: 40px;
  padding: 10px 15px;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 18px;
  border-radius: 4px;
  overflow: hidden;
`;

const UL = styled.ul`
  list-style-type: none;
`;

const LI = styled.li`
  text-align: center;
  padding: 10px;
  width: 80px;
  cursor: pointer;
  &:hover {
    background-color: #5783db;
    color: #fff;
  }
`;

const UserMenu = ({ showMenu }) => {
  const Navigate = useNavigate();
  return (
    <Main onClick={() => showMenu(false)}>
      <UL>
        <LI onClick={() => Navigate("/profile")}>Profile</LI>
        <LI onClick={() => Navigate("/")}>Logout</LI>
      </UL>
    </Main>
  );
};

export default UserMenu;
