// MainComponent.js
import React, { useEffect, useState } from "react";
import ModalComponent from "../../Components/ModalComponent";
import "../Indent/View/IndentPageStyle.css";
import { fixDateHandler } from "./ReceiptIntimation";
import { IoCheckmark } from "react-icons/io5";
import { IoCheckmarkDone } from "react-icons/io5";
// import { AiOutlineStop } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../../Components/Loader/LoadingContext";
import { FaRegTrashAlt } from "react-icons/fa";

import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useAxiosInstances from "../../services/axiosconfig";

const theme = createTheme();

const MainComponent = () => {
  const { SolzonMain } = useAxiosInstances();

  const [data, setData] = useState([]);
  const [years, setYears] = useState([]);
  const [singleYears, setSingleYears] = useState("");
  const [customerName, setCustomerName] = useState([]);
  const [singleCustomerName, setSingleCustomerName] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const [modalContent, setModalContent] = useState([]);
  const [fyCd, setFyCd] = useState(null);

  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    getCustomers();
    getYears();
  }, []);

  const deleteReceiptHandler = async (id) => {
    closeModal(false);
    try {
      const { data } = await SolzonMain.delete(
        "https://aisapi.solzon.in/api/ReceiptIntimation/DeleteReceiptIntimation",
        {
          params: {
            p_ri_id: id,
          },
        },
      );
      handleViewAllIndent();
      toast.success(data.data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message);
    }
  };

  const getYears = async () => {
    try {
      const { data } = await SolzonMain.get(
        "https://aisapi.solzon.in/api/FinancialYear/List?au_type=A",
      );
      setYears(data.data);
      handleShowAll(data?.data[0]);
      setFyCd({ fy_cd: data?.data[0]?.fy_cd });
      setSingleYears(data?.data[0]?.fy_cd);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomers = async () => {
    try {
      const { data } = await SolzonMain.get(
        `https://aisapi.solzon.in/api/Customers/List`,
        {
          params: {
            aup_cd: localStorage.getItem("aup_cd"),
            au_type: localStorage.getItem("au_typ"),
          },
        },
      );
      setSingleCustomerName(data.data[0]?.ac_cd);
      setCustomerName(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowAll = async (defaultYear) => {
    setIsLoading(true);
    try {
      const { data } = await SolzonMain.get(
        "https://aisapi.solzon.in/api/ReceiptIntimation/GetAll",
        {
          params: {
            p_fy_cd: defaultYear.fy_cd,
            p_ac_cd: localStorage.getItem("aup_cd"),
          },
        },
      );
      setData(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // const handleValueFromChild = (value, value1) => {
  //   console.log(value, value1);
  //   setIsModalOpen(value1);
  // };

  const handleViewAllIndent = async (actionName, action, event) => {
    setIsLoading(true);
    if (action) {
      action(event);
    }
    SolzonMain.get(`https://aisapi.solzon.in/api/ReceiptIntimation/GetAll`, {
      params: {
        p_fy_cd: actionName === "years" ? event : singleYears,
        p_ac_cd: actionName === "customer" ? event : singleCustomerName,
      },
    })
      .then((res) => {
        setData(res.data.data);
        console.log(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console(err);
        setIsLoading(false);
      });
  };
  // const handleViewAllInadent = (actionName, action, event) => {
  //   let queryParms = {};
  //   action(event);
  //   if (actionName === "customer" && event && years) {
  //     queryParms["p_ac_cd"] = event;
  //     queryParms["p_fy_cd"] = singleYears;
  //   } else if (actionName === "years" && event && customerName) {
  //     queryParms["p_fy_cd"] = event;
  //     queryParms["p_ac_cd"] = singleCustomerName;
  //   }
  //   SolzonMain
  //     .get(`https://aisapi.solzon.in/api/ReceiptIntimation/GetAll`, {
  //       params: {
  //         p_fy_cd: queryParms.p_fy_cd,
  //         p_ac_cd: queryParms.p_ac_cd,
  //       },
  //     })
  //     .then((response) => {
  //       setData(response.data.data);
  //       console.log(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const modalOpenHandler = (content) => {
    console.log(content);
    setModalContent(content);
    setIsModalOpen(true);
  };

  const renderStatus = (status) => {
    switch (status) {
      case -1 || "-1":
        return <FaRegTrashAlt size={20} color="red" />;

      case 0 || "0":
        return <IoCheckmark size={20} color="green" />;

      case 1 || "1":
        return <IoCheckmarkDone size={20} color="green" />;

      case 2 || "2":
        return <FaRegTrashAlt size={20} color="red" />;
      // return <AiOutlineStop size={20} color="red" />;

      default:
        break;
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      console.log(newValue.ac_cd);
      handleViewAllIndent("customer", setSingleCustomerName, newValue.ac_cd);
    } else {
      handleViewAllIndent("customer", setSingleCustomerName, null);
    }
  };

  const handleAutocompleteChangeFinancialYear = (event, newValue) => {
    if (newValue) {
      console.log(newValue.ac_cd);
      setFyCd({ fy_cd: newValue.fy_cd });
      handleViewAllIndent("years", setSingleCustomerName, newValue.fy_cd);
    } else {
      handleViewAllIndent("years", setSingleCustomerName, null);
    }
  };

  return (
    <div>
      {/* {isLoading && <Loader />} */}
      <ToastContainer />
      <h3 style={{ margin: "2% 2vw" }}>View Receipt Intimation</h3>
      <div className="indent-form">
        <div className="indent-form-one">
          {/* <label>Customer: </label>
          <select
            onChange={(e) =>
              handleViewAllIndent("customer", setSingleCustomerName, e)
            }
          >
            <option value="">--Customer Name--</option>
            {customerName.map((item, index) => (
              <option value={item.ac_cd} key={index}>
                {item.ac_nm}
              </option>
            ))}
          </select> */}
          <ThemeProvider theme={theme}>
            <Autocomplete
              options={years}
              getOptionLabel={(option) => option.fy_cd}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Financial Year"
                />
              )}
              value={fyCd}
              style={{ width: 400 }}
              onChange={handleAutocompleteChangeFinancialYear}
            />
          </ThemeProvider>
        </div>
        <div className="indent-form-one">
          {/* <label>Financial Year: </label>
          <select
            onChange={(e) => handleViewAllIndent("years", setSingleYears, e)}
          >
            {years.map((item, index) => (
              <option value={item.fy_cd} key={index}>
                {item.fy_cd}
              </option>
            ))}
          </select> */}
          <ThemeProvider theme={theme}>
            <Autocomplete
              options={customerName}
              getOptionLabel={(option) => option.ac_nm}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Customer Name"
                />
              )}
              style={{ width: 400 }}
              onChange={handleAutocompleteChange}
            />
          </ThemeProvider>
        </div>
      </div>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              <th> Number</th>
              <th>Date</th>
              <th>Company</th>
              <th>Deposited In</th>
              <th>Amount</th>
              <th>V. No.</th>
              <th>V. Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => {
              return (
                <tr onClick={() => modalOpenHandler(item.billItem)}>
                  <td>{item.ri_no}</td>
                  <td>{fixDateHandler(item.ri_dt)}</td>
                  <td>{item.co_nm}</td>
                  <td>{item.hb_nm}</td>
                  <td>{item.chq_amt}</td>
                  <td>{item.bb_no === 0 ? "" : item.bb_no} </td>
                  <td>{fixDateHandler(item.bb_dt)}</td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteReceiptHandler(item.ri_id);
                    }}
                  >
                    {renderStatus(Number(item.sts))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <div className="indent-table"> */}
      {/* <TableComponent
          data={data}
          childValueChange={handleValueFromChild}
          searchColumn="ac_nm"
        /> */}
      <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
        <div className="modalStuff">
          <div className="tableContainer">
            <table className="table">
              <thead>
                <tr>
                  <th>Txn. No.</th>
                  <th>Txn. Date</th>
                  <th>Amount</th>
                  <th>Adjusted Amt. </th>
                  <th>CD Amt</th>
                  <th>Discount</th>
                  <th>Remarks</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {modalContent.map((content) => (
                  <tr>
                    <td>{content.tt_txn_no}</td>
                    <td>{fixDateHandler(content.txn_dt)}</td>
                    <td>{content.txn_amt}</td>
                    <td>{content.txn_adj}</td>
                    <td>{content.amt_cd}</td>
                    <td>{content.amt_disc}</td>
                    <td>{content.txn_rmrk}</td>
                    <td>{renderStatus(content.txn_sts)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <h2>Indent Number</h2>
        <p>Party Number</p>
        <TableModalComponent
          data={data}
          childValueChange={handleValueFromChild}
          searchColumn="ac_nm"
        /> */}
      </ModalComponent>
      {/* </div> */}
    </div>
  );
};

export default MainComponent;
