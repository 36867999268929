import axios from "axios";

// console.log(accessToken)
// axios.interceptors.request.use((config) => {
//     config.headers['Authorization'] = `Bearer ${accessToken}`;
//     console.log(config.url)
//     console.log(config.headers.Authorization)
//     return config;
// }, error => {
//     console.log(error, "INTTTs")
//     toast.error('Something went wrong')
//     return Promise.reject(error)
// })

const useAxiosInstances = () => {
  const accessToken = localStorage.getItem("validToken");

  const SolzonMain = axios.create({
    baseURL: "https://aisapi.solzon.in/api",
  });

  SolzonMain.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      console.log(config.url);
      return config;
    },
    (error) => {
      console.log(error, "INTTTs");
    },
  );

  return {
    SolzonMain,
  };

  //     const handleRequest = (config) => {
  //         config.headers["authorization"] = `Bearer ${accessToken}`;
  //         return config;
  //     };

  //     const handleError = (error) => Promise.reject(error);

  //     const handleResponseError = (error) => {
  //         if (error.response && error.response.status === 403) {
  //         }
  //         if (error.response && error.response.status === 500) {
  //             toast.error('Something went wrong. Please try again later.')
  //         }
  //         return Promise.reject(error);
  //     };

  //     const User = axios.create({
  //         baseURL: `${baseURL}/users`,
  //     });

  //     const UserWithHeaderAPI = axios.create({
  //         baseURL: `${baseURL}/users`,
  //     });

  //     UserWithHeaderAPI.interceptors.request.use(handleRequest, handleError);
  //     UserWithHeaderAPI.interceptors.response.use(undefined, handleResponseError);

  //     const CreditScoreAPI = axios.create({
  //         baseURL: `${baseURL}/creditscore`,
  //     });

  //     CreditScoreAPI.interceptors.request.use(handleRequest, handleError);
  //     CreditScoreAPI.interceptors.response.use(undefined, handleResponseError);

  //     const ApplyLoanAPI = axios.create({
  //         baseURL: `${baseURL}/appliedloan`,
  //     });

  //     ApplyLoanAPI.interceptors.request.use(handleRequest, handleError);
  //     ApplyLoanAPI.interceptors.response.use(undefined, handleResponseError);

  //     const ActiveLoanAPI = axios.create({
  //         baseURL: `${baseURL}/activeloan`,
  //     });

  //     ActiveLoanAPI.interceptors.request.use(handleRequest, handleError);
  //     ActiveLoanAPI.interceptors.response.use(undefined, handleResponseError);

  //     return {
  //         User,
  //         CreditScoreAPI,
  //         ApplyLoanAPI,
  //         ActiveLoanAPI,
  //         UserWithHeaderAPI,
  //     };
};

export default useAxiosInstances;
