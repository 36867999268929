import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";

const FullContainer = styled.section`
  display: flex;
  overflow-y: hidden;
`;

const ContentContainer = styled.div`
  /* border: 1px solid black; */
  box-shadow: 0 0 4px 0px #972c2665;
  border-radius: 10px;
  width: auto;
  overflow-y: auto;
  min-height: 80vh;
  max-height: 80vh;
  margin: 5vh 2vw 5vh 2vw;
  overflow-x: hidden;
`;

const RestContainer = styled.div`
  overflow-y: hidden;
  flex: 1;
`;

const MainContainer = () => {
  const [collapse, setCollapse] = useState(true);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <FullContainer>
      {!isTabletOrMobile && collapse ? <Sidebar /> : null}
      <RestContainer>
        <Navbar sidebarStatus={collapse} collapse={setCollapse} />
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </RestContainer>
    </FullContainer>
  );
};

export default MainContainer;
