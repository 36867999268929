import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import styles from "./Reportpage.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useSolzonMainInstances from "../../services/axiosconfig";
import ModalComponent from "../../Components/ModalComponent";
import * as xlsx from "xlsx";

const theme = createTheme();

const MainComponent = () => {
  const { SolzonMain } = useSolzonMainInstances();

  const replacements = {
    ac_cd: "Code",
    ac_nm: "Customer Name",
    amt: "Invoice Name",
    amt_bal: "Balance",
    txn_no: "Transaction Number",
    txn_dt: "Transaction Date",
    cddd: "CD Due Date",
    mns: "Items",
    dy_ps: "Days Passed",
  };

  let listOfReports = [
    {
      reportName: "Outstanding Bills",
      url: "https://aisapi.solzon.in/api/AISReport/OutstandingBillRpt?p_ca_cd=AA029",
      url2: "https://aisapi.solzon.in/api/AISReport/CustomerOutstandingBillRPT?p_ca_cd=AA0029&p_ac_cd=DDES010",
    },
  ];

  const [currentReport, setCurrentReport] = useState("");
  const [reportData, setreportData] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportDataModal, setreportDataModal] = useState([]);
  const [columnsListModal, setColumnsListModal] = useState([]);

  const closeModal = () => {
    setIsModalOpen(false);
    setreportDataModal([]);
  };

  const fetchReport = async (event, newValue) => {
    try {
      if (!newValue) return;
      setCurrentReport(newValue);
      let response;
      response = await fetch(newValue?.url);
      setreportData(replaceKeys(response.data, replacements));
      let columns = Array.from(
        new Set(response.data.flatMap((item) => Object.keys(item))),
      );
      setColumnsList(replaceArrayValues(columns, replacements));
    } catch (err) {
      console.log(err);
      throw new Error("Network response was not ok");
    }
  };

  const fetch = async (url) => {
    try {
      let result = await SolzonMain.get(url);
      return result.data;
    } catch (err) {
      console.log(err);
      throw new Error("Network response was not ok");
    }
  };

  const downloadReport = async () => {
    try {
      if (reportData.length === 0 || !currentReport)
        return toast.error("Please select the report.");
      var report, wb;
      report = xlsx.utils.json_to_sheet(reportData);
      wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, report, "Sample");
      xlsx.writeFile(wb, "Report.xlsx");
    } catch (err) {
      console.log(err);
    }
  };

  const openModal = async () => {
    setIsModalOpen(true);
    let response = await fetch(currentReport?.url2);
    setreportDataModal(replaceKeys(response.data, replacements));
    let data = response.data;
    const columns = Array.from(
      new Set(data.flatMap((item) => Object.keys(item))),
    );
    setColumnsListModal(replaceArrayValues(columns, replacements));
  };

  const downloadSecondReport = async () => {
    try {
      if (reportDataModal.length === 0 || !currentReport)
        return toast.error("Please wait...");
      var report, wb;
      report = xlsx.utils.json_to_sheet(reportDataModal);
      wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, report, "Sample");
      xlsx.writeFile(wb, "Report.xlsx");
    } catch (err) {
      console.log(err);
    }
  };

  function replaceArrayValues(originalArray, replacements) {
    // Replace values in the array based on the replacements map
    return originalArray
      .map((item) => replacements[item] || null) // Replace if found, otherwise null
      .filter(Boolean); // Remove null values (non-matching entries)
  }

  function replaceKeys(data, keyMap) {
    // Replace keys in each object and remove keys not in the keyMap
    return data.map((item) => {
      const newItem = {};
      for (const key in item) {
        if (keyMap[key]) {
          newItem[keyMap[key]] = item[key]; // Replace key with mapped value
        }
      }
      return newItem; // Return object with only keys in keyMap
    });
  }

  return (
    <div>
      <ToastContainer />
      <div className={styles.reportMain}>
        <ThemeProvider theme={theme}>
          <Autocomplete
            options={listOfReports}
            getOptionLabel={(option) => option.reportName}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Reports"
                style={{ zIndex: 0 }}
                required
              />
            )}
            style={{ width: 400 }}
            onChange={fetchReport}
          />
        </ThemeProvider>
        <button className={styles.buttonMain} onClick={downloadReport}>
          Download
        </button>
      </div>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              {columnsList.map((column) => {
                return <th key={column}>{column}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {reportData.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => openModal(row)}>
                {columnsList.map((column) => (
                  <td key={column}>
                    {row[column] !== undefined ? row[column] : "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isModalOpen && (
          <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
            <div className="modalStuff">
              <button
                className={styles.buttonMain}
                onClick={downloadSecondReport}
              >
                Download
              </button>
              <div className="tableContainer">
                <table className="table">
                  <thead>
                    <tr>
                      {columnsListModal.map((column) => {
                        return <th key={column}>{column}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {reportDataModal.map((row, rowIndex) => (
                      <tr key={rowIndex} onClick={() => openModal(row)}>
                        {columnsListModal.map((column) => (
                          <td key={column}>
                            {row[column] !== undefined ? row[column] : "-"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ModalComponent>
        )}
      </div>
    </div>
  );
};

export default MainComponent;
