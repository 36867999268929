import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const MobileNavContainer = styled.div`
  position: absolute;
  width: 80%;
  top: 10vh;
  z-index: 200;
  margin-left: 2vw;
  background-color: #fff;
`;

const MobileTabs = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000;
  font-weight: 600;
  color: #5783db;
  justify-content: left;
  padding: 15px 10px;
`;

const MobileNav = ({ setOpenMobileNav }) => {
  const changeRouteHandler = (route) => {
    Navigate(route);
    setOpenMobileNav(false);
  };
  const Navigate = useNavigate();
  return (
    <MobileNavContainer>
      <MobileTabs onClick={() => changeRouteHandler("/dashboard")}>
        Dashboard
      </MobileTabs>
      <MobileTabs onClick={() => changeRouteHandler("/indentbooking")}>
        Indent Booking
      </MobileTabs>
      <MobileTabs onClick={() => changeRouteHandler("/receiptintimation")}>
        Payment Intimation
      </MobileTabs>
      <MobileTabs onClick={() => changeRouteHandler("/documentapproval")}>
        Document Approval
      </MobileTabs>
    </MobileNavContainer>
  );
};

export default MobileNav;
