import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PIN_REGEX = /^\d{6}$/;
const MOBILE_REGEX = /^[6789]\d{9}$/;
// const NAME_REGEX = /^[a-zA-Z\s]{1,50}$/;

const Profile = () => {
  const Navigate = useNavigate();
  const [input, setInput] = useState({
    email: localStorage.getItem("email"),
    mbl: localStorage.getItem("mbl"),
    aup_pin: "",
    cnfPin: "",
    aup_nm: localStorage.getItem("aup_nm"),
  });
  const [regexErr, setRegexErr] = useState({
    email: false,
    mbl: false,
    aup_pin: false,
    cnfPin: false,
  });
  const inputHandler = (state) => {
    return (e) => {
      setInput((prevState) => ({
        ...prevState,
        [state]: e.target.value,
      }));
    };
  };

  const updateProfile = async () => {
    const invalidInp = Object.values(regexErr).some((val) => val);
    if (invalidInp) {
      toast.error("Please check all the fields and try again");
      return;
    }
    try {
      console.log(input);
      const { data } = await axios.post(
        `https://aisapi.solzon.in/api/User/Registration`,
        {
          aup_cd: localStorage.getItem("aup_cd"),
          ...input,
        },
      );
      Navigate("/dashboard");
      toast.success("Profile updated successfully");
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (input.aup_nm?.length === 0 || !input.aup_nm) {
  //     setRegexErr((prevState) => ({
  //       ...prevState,
  //       aup_nm: false,
  //     }));
  //     return;
  //   }
  //   const validEmail = NAME_REGEX.test(input.aup_nm);
  //   setRegexErr((prevState) => ({
  //     ...prevState,
  //     aup_nm: !validEmail,
  //   }));
  // }, [input.aup_nm]);

  useEffect(() => {
    if (input.email?.length === 0 || !input.email) {
      setRegexErr((prevState) => ({
        ...prevState,
        email: false,
      }));
      return;
    }
    const validEmail = EMAIL_REGEX.test(input.email);
    setRegexErr((prevState) => ({
      ...prevState,
      email: !validEmail,
    }));
  }, [input.email]);

  useEffect(() => {
    if (input.mbl?.length === 0 || input.mbl === undefined) {
      setRegexErr((prevState) => ({
        ...prevState,
        mbl: false,
      }));
      return;
    }
    const validMobile = MOBILE_REGEX.test(input.mbl);
    setRegexErr((prevState) => ({
      ...prevState,
      mbl: !validMobile,
    }));
  }, [input.mbl]);

  useEffect(() => {
    if (input.aup_pin?.length === 0 || input.aup_pin === undefined) {
      setRegexErr((prevState) => ({
        ...prevState,
        aup_pin: false,
      }));
      return;
    }
    const validPin = PIN_REGEX.test(input.aup_pin);
    setRegexErr((prevState) => ({
      ...prevState,
      aup_pin: !validPin,
    }));
  }, [input.aup_pin]);

  useEffect(() => {
    if (input.cnfPin?.length === 0 || input.cnfPin === undefined) {
      setRegexErr((prevState) => ({
        ...prevState,
        cnfPin: false,
      }));
      return;
    }
    const validCnf = input.aup_pin === input.cnfPin;
    console.log(validCnf);
    setRegexErr((prevState) => ({
      ...prevState,
      cnfPin: !validCnf,
    }));
  }, [input.cnfPin, input.aup_pin]);

  return (
    <>
      <ToastContainer />
      <section className={styles.main}>
        <div className={styles.Header}>
          <h1>Profile Details</h1>
        </div>
        <div className={styles.form}>
          <div className={styles.inputContainer}>
            <label htmlFor="name">Name</label>
            <div>
              <input
                type="text"
                value={input.aup_nm}
                onChange={inputHandler("aup_nm")}
                maxLength={50}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="email">Email</label>
            <div>
              <input
                type="email"
                value={input.email}
                onChange={inputHandler("email")}
              />
              {regexErr.email && <p>Invalid Email</p>}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="phone">Phone</label>
            <div>
              <input
                maxLength={10}
                value={input.mbl}
                type="number"
                onChange={inputHandler("mbl")}
              />
              {regexErr.mbl && <p>Invalid Phone</p>}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="phone">Pin</label>
            <div>
              <input
                type="password"
                maxLength={6}
                onChange={inputHandler("aup_pin")}
              />
              {regexErr.aup_pin && <p>Invalid Pin</p>}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="phone">Confirm Pin</label>
            <div>
              <input type="password" onChange={inputHandler("cnfPin")} />
              {regexErr.cnfPin && <p>Password does not match</p>}
            </div>
          </div>
        </div>
        <div className={styles.submit}>
          <button onClick={updateProfile}>Submit</button>
        </div>
      </section>
    </>
  );
};

export default Profile;
